import {
  BulbOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileSearchOutlined,
  LinkOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Button, Table, Tag, Tooltip } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { SortOrder, TableCurrentDataSource } from 'antd/lib/table/interface'
import { t } from 'i18next'
import moment from 'moment'
import { AlignType } from 'rc-table/lib/interface'
import { MouseEvent, useCallback, useMemo } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  dateFormat,
  literatureReviewSearchQueryResultItemStatus,
  literatureReviewSearchQueryResultItemStatusColorKeys,
  literatureReviewSearchQueryResultItemStatusTextMap,
  privacyTypes,
} from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { setFillSearchFormData } from '../store/reducers/searchResultInfoReducer'
import { BackEndSortOrder } from '../types/generalTypes'
import SimpleSwitch from './SimpleSwitch'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import TableBoldColumnContainer from './display/TableBoldColumnContainer'

const FirstLineContainer = styled.div`
  margin: 0 8px 4px 8px;
`

const SecondLineContainer = styled.div`
  font-size: 12px;
  margin: 0 8px 0 8px;
`

const LanguageContainer = styled.div`
  text-transform: capitalize;
`

export interface SearchTableRow {
  id: string
  key: string
  title?: string
  publicationName?: string
  authors?: string[]
  publicationDate?: string
  included?: boolean
  documentLink?: string
  language?: string
  summary?: string
  tags?: string
  summaryStatus?: string
}

interface SearchTableWidths {
  title: string
  authors: string
  publicationDate: string
  summaryStatus?: string
  includeExclude?: string
  actions: string
}

interface SearchTableProps {
  formattedData: SearchTableRow[]
  useInclusionToggle?: boolean
  onSwitchClick?: (recordId: string) => void
  loadingIds?: string[]
  onClick?: (recordId: string) => void
  maxHeight?: string
  usePagination?: true
  currentPage?: number
  setCurrentPage?: (page: number) => void
  refreshData?: (
    page: number,
    sortField?: string,
    sortOrder?: BackEndSortOrder
  ) => void
  totalResult?: string | number
  useBackEndSort?: boolean
  currentSort?: string
  dataSourceVisibility?: string
  onSummarize?: (recordId: string) => void
}

const SearchTable = ({
  formattedData,
  useInclusionToggle,
  onSwitchClick,
  loadingIds,
  onClick,
  maxHeight,
  usePagination,
  currentPage,
  refreshData,
  totalResult,
  useBackEndSort,
  currentSort,
  dataSourceVisibility,
  onSummarize,
}: SearchTableProps) => {
  const location = useLocation()

  const dispatch = useDispatch()

  const isSearchPage = useMemo(() => {
    return location.pathname.includes(routePaths.SEARCH)
  }, [location])

  const navigate = useNavigate()

  const renderAuthors = (record: SearchTableRow) => (
    <>
      <FirstLineContainer>
        {record.publicationName || t('searchTable.noJournalAvailable')}
      </FirstLineContainer>
      <SecondLineContainer>
        {record.authors?.join(', ') || t('searchTable.noAuthorAvailable')}
      </SecondLineContainer>
    </>
  )

  const handleFindSimilar = useCallback(
    (event: MouseEvent, searchText?: string) => {
      event.stopPropagation()
      dispatch(setFillSearchFormData({ searchTextValue: searchText }))
      navigate(routePaths.SEARCH)
    },
    [dispatch, navigate]
  )

  const handleSummarize = useCallback(
    (event: MouseEvent, id?: string) => {
      event.stopPropagation()
      if (onSummarize) {
        onSummarize(id || '')
      }
    },
    [onSummarize]
  )

  const renderActionButtons = (record: SearchTableRow) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '8px',
      }}
    >
      <Button
        type="link"
        color="default"
        icon={<LinkOutlined />}
        href={record.documentLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      />
      <Tooltip
        title={
          isSearchPage
            ? t('searchTable.findSimilarTooltip')
            : t('searchTable.summarizeTooltip')
        }
        placement="left"
        align={{ offset: [-24, 0] }}
      >
        <Button
          type="default"
          icon={isSearchPage ? <FileSearchOutlined /> : <BulbOutlined />}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event: MouseEvent) =>
            isSearchPage
              ? handleFindSimilar(event, record.tags)
              : handleSummarize(event, record.id)
          }
        />
      </Tooltip>
    </div>
  )

  const literatureReviewSearchQueryResultItemStatusIcons = {
    [literatureReviewSearchQueryResultItemStatus.PROCESSING]: (
      <SyncOutlined spin />
    ),
    [literatureReviewSearchQueryResultItemStatus.READY]: (
      <CheckCircleOutlined />
    ),
    [literatureReviewSearchQueryResultItemStatus.FAILED]: (
      <CloseCircleOutlined />
    ),
    [literatureReviewSearchQueryResultItemStatus.INGESTION]: (
      <SyncOutlined spin />
    ),
  }

  const getColumnWidths = (isSearchPage: boolean): SearchTableWidths => {
    return isSearchPage
      ? { title: '35%', authors: '35%', publicationDate: '20%', actions: '20%' }
      : {
          title: '35%',
          authors: '20%',
          publicationDate: '15%',
          summaryStatus: '10%',
          includeExclude: '10%',
          actions: '10%',
        }
  }

  const getColumnsConfig = (): ColumnsType<SearchTableRow> => {
    const tableWidths = getColumnWidths(isSearchPage)
    return [
      {
        title: t('Title'),
        dataIndex: 'title',
        key: 'title',
        width: tableWidths.title,
        render: (text: string) => (
          <TableBoldColumnContainer data-testid="search-table-title">
            {text || t('searchTable.noTitleAvailable')}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('searchTable.publicationTitle'),
        dataIndex: 'authors',
        key: 'authors',
        width: tableWidths.authors,
        sortDirections: ['ascend'] as SortOrder[],
        sorter: useBackEndSort
          ? (a: SearchTableRow, b: SearchTableRow) =>
              a.publicationName && b.publicationName
                ? a.publicationName.localeCompare(b.publicationName)
                : -1
          : undefined,
        sortOrder:
          useBackEndSort &&
          dataSourceVisibility?.toLocaleUpperCase() === privacyTypes.PUBLIC &&
          currentSort &&
          (currentSort as SorterResult<SearchTableRow>).field === 'authors'
            ? (currentSort as SorterResult<SearchTableRow>).order
            : undefined,
        render: (_: string, record: SearchTableRow) => renderAuthors(record),
      },
      {
        title: t('searchTable.publicationDate'),
        dataIndex: 'publicationDate',
        key: tableWidths.publicationDate,
        width: tableWidths.publicationDate,
        sortDirections: ['descend'] as SortOrder[],
        sorter: useBackEndSort
          ? (a: SearchTableRow, b: SearchTableRow) =>
              a.publicationDate && b.publicationDate
                ? moment(a.publicationDate).valueOf() -
                  moment(b.publicationDate).valueOf()
                : -1
          : undefined,
        sortOrder:
          useBackEndSort &&
          dataSourceVisibility?.toLocaleUpperCase() === privacyTypes.PUBLIC &&
          currentSort &&
          (currentSort as SorterResult<SearchTableRow>).field ===
            'publicationDate'
            ? (currentSort as SorterResult<SearchTableRow>).order
            : undefined,
        render: (_: string, record: SearchTableRow) => (
          <>
            <FirstLineContainer>
              {record.publicationDate ? (
                <Moment local format={dateFormat.PRIMARY}>
                  {record.publicationDate}
                </Moment>
              ) : (
                t('searchTable.noDateAvailable')
              )}
            </FirstLineContainer>
            <SecondLineContainer>
              <LanguageContainer>
                {record.language || t('searchTable.noLanguageAvailable')}
              </LanguageContainer>
            </SecondLineContainer>
          </>
        ),
      },
      {
        title: t('searchTable.summaryStatus'),
        dataIndex: 'summaryStatus',
        key: 'summaryStatus',
        width: tableWidths.summaryStatus || '10%',
        render: (_: string, record: SearchTableRow) => {
          const summaryStatus = record.summaryStatus || ''
          const tagText =
            summaryStatus in literatureReviewSearchQueryResultItemStatusTextMap
              ? literatureReviewSearchQueryResultItemStatusTextMap[
                  summaryStatus
                ]
              : ''
          return (
            <>
              {tagText && (
                <Tag
                  icon={
                    literatureReviewSearchQueryResultItemStatusIcons[
                      summaryStatus
                    ]
                  }
                  color={
                    literatureReviewSearchQueryResultItemStatusColorKeys[
                      summaryStatus
                    ] || 'default'
                  }
                >
                  {tagText}
                </Tag>
              )}
            </>
          )
        },
      },
      {
        title: (
          <>
            {t('searchTable.include')}/<br />
            {t('searchTable.exclude')}
          </>
        ),
        dataIndex: 'includeExclude',
        key: 'includeExclude',
        width: tableWidths.includeExclude || '10%',
        render: (_: string, record: SearchTableRow) => (
          <SimpleSwitchContainer>
            <SimpleSwitch
              data-testid={`simple-switch-${record.id}`}
              checked={record.included}
              onClick={() => onSwitchClick && onSwitchClick(record.id)}
              loading={loadingIds?.includes(record.id)}
              disabled={loadingIds && loadingIds?.length > 0}
            />
            <div data-testid={`simple-switch-text-${record.id}`}>
              {record.included ? t('Included') : t('Excluded')}
            </div>
          </SimpleSwitchContainer>
        ),
      },
      {
        title: t('Actions'),
        key: 'action',
        dataIndex: 'action',
        align: 'right' as AlignType,
        width: tableWidths.actions || '20%',
        render: (_: string, record: SearchTableRow) =>
          renderActionButtons(record),
      },
    ].filter((col) =>
      !isSearchPage
        ? true
        : col.dataIndex !== 'includeExclude' &&
          col.dataIndex !== 'summaryStatus'
    )
  }

  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SearchTableRow> | SorterResult<SearchTableRow>[],
    extra: TableCurrentDataSource<SearchTableRow>
  ) => {
    if (refreshData) {
      refreshData(
        pagination.current || 0,
        useBackEndSort
          ? (sorter as SorterResult<SearchTableRow>).field?.toString()
          : undefined,
        useBackEndSort
          ? (sorter as SorterResult<SearchTableRow>).order === 'descend'
            ? 'DESC'
            : 'ASC'
          : undefined
      )
    }
  }

  return (
    <Table
      rowClassName="page-list-table-row clickable"
      columns={getColumnsConfig()}
      dataSource={formattedData}
      onChange={handleOnChange}
      pagination={
        usePagination
          ? {
              current: currentPage,
              total: Number(totalResult),
              showSizeChanger: false,
              pageSize: 100,
              position: ['bottomLeft'],
            }
          : false
      }
      scroll={{ y: maxHeight }}
      onRow={(record, rowIndex) => ({
        'data-testid': `searchTableRow${rowIndex}`,
        onClick: (event) => {
          onClick && onClick(record.id)
        }, // click row
      })}
    />
  )
}

export default SearchTable
